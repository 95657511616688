import axios from "axios";
import { getHeader, priceListUrl, productUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    productTypeList: [],
    productCategoryList: [],
    productList: [],
    productMainList: [],
    productUomList: [],
    productVariableList: [],
    productPriceTypeList: [],
    productPriceList: [],
    productOptionListByProductNo: [],
    showProductListModal: false,
    showProductCostSyncModal: false,
    showProductImportModal: false,
    showProductCostSyncModal: false,
    showProductPriceImportModal: false,
    showProductOptionalModal: false,
    showProductVariableFormModal: false,
    showProductOptionalFormModal: false
  },
  mutations: {
    SET_PRODUCT_PRICE_LIST (state, obj) {
      state.productPriceList = obj
    },
    SET_PRODUCT_PRICE_TYPE_LIST (state, obj) {
      state.productPriceTypeList = obj
    },
    SET_PRODUCT_VARIABLE_LIST (state, obj) {
      state.productVariableList = obj
    },
    SET_PRODUCT_TYPE_LIST (state, obj) {
      state.productTypeList = obj
    },
    SET_PRODUCT_CATEGORY_LIST (state, obj) {
      state.productCategoryList = obj
    },
    SET_PRODUCT_LIST (state, obj) {
      state.productList = obj
    },
    PUSH_PRODUCT_LIST (state, obj) {
      for (let a = 0; a < obj.length; a++) {
        const pl = state.productList.find(d => d.product_no === obj[a].product_no)
        if (pl) {
          const pl2 = state.productList.find(d => d.product_sku === obj[a].product_sku)
          // check if sku
          if (pl2) {
          } else {
            state.productList.push(obj[a])
          }
        } else {
          state.productList.push(obj[a])
        }
      }
    },
    PUSH_PRODUCT_MAIN_LIST (state, obj) {
      for (let a = 0; a < obj.length; a++) {
        const pl = state.productMainList.find(d => d.product_no === obj[a].product_no)
        if (pl) {
        } else {
          state.productMainList.push(obj[a])
        }
      }
    },
    SET_PRODUCT_MAIN_LIST (state, obj) {
      state.productMainList = obj
    },
    SET_PRODUCT_LIST_MODAL (state, obj) {
      state.showProductListModal = obj
    },
    SET_PRODUCT_IMPORT_MODAL (state, obj) {
      state.showProductImportModal = obj
    },
    SET_SHOW_PRODUCT_COST_SYNC_MODAL (state, obj) {
      state.showProductCostSyncModal = obj
    },
    SET_PRODUCT_UOM_LIST (state, obj) {
      state.productUomList = obj
    },
    SET_PRODUCT_PRICE_LIST_IMPORT_MODAL (state, obj) {
      state.showProductPriceImportModal = obj
    },
    SET_PRODUCT_OPTIONAL_MODAL (state, obj) {
      state.showProductOptionalModal = obj
    },
    SET_SHOW_PRODUCT_VARIABLE_FORM_MODAL (state, obj) {
      state.showProductVariableFormModal = obj
    },
    SET_SHOW_PRODUCT_OPTIONAL_FORM_MODAL (state, obj) {
      state.showProductOptionalFormModal = obj
    }
  },
  actions: {
    enableProductOptionalFormModal ({ commit }) {
      commit('SET_SHOW_PRODUCT_OPTIONAL_FORM_MODAL', true)
    },
    disableProductOptionalFormModal ({ commit }) {
      commit('SET_SHOW_PRODUCT_OPTIONAL_FORM_MODAL', true)
    },
    enableProductVariableFormModal ({ commit }) {
      commit('SET_SHOW_PRODUCT_VARIABLE_FORM_MODAL', true)
    },
    disableProductVariableFormModal ({ commit }) {
      commit('SET_SHOW_PRODUCT_VARIABLE_FORM_MODAL', false)
    },
    enableProudctPriceListImportModal ({ commit }) {
      commit('SET_PRODUCT_PRICE_LIST_IMPORT_MODAL', true)
    },
    disableProudctPriceListImportModal ({ commit }) {
      commit('SET_PRODUCT_PRICE_LIST_IMPORT_MODAL', false)
    },
    enableProductCostSyncModal({ commit }) {
      commit('SET_SHOW_PRODUCT_COST_SYNC_MODAL', true)
    },
    disableProductCostSyncModal({ commit }) {
      commit('SET_SHOW_PRODUCT_COST_SYNC_MODAL', false)
    },
    enableProductListModal({ commit }) {
      commit('SET_PRODUCT_LIST_MODAL', true)
    },
    disableProductListModal({ commit }) {
      commit('SET_PRODUCT_LIST_MODAL', false)
    },
    enableProductImportModal({ commit }) {
      commit('SET_PRODUCT_IMPORT_MODAL', true)
    },
    disableProductImportModal({ commit }) {
      commit('SET_PRODUCT_IMPORT_MODAL', false)
    },
    enableProductOptionalModal({ commit }) {
      commit('SET_PRODUCT_OPTIONAL_MODAL', true)
    },
    disableProductOptionalModal({ commit }) {
      commit('SET_PRODUCT_OPTIONAL_MODAL', false)
    },
    async getProductPriceList({ commit }) {
      try {
        const data = await axios.get(priceListUrl, { headers: getHeader() })
        commit('SET_PRODUCT_PRICE_LIST', data.data.data)
      } catch(error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductPriceTypeList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/price/type', { headers: getHeader() })
        commit('SET_PRODUCT_PRICE_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductVariableList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/variable', { headers: getHeader() })
        commit('SET_PRODUCT_VARIABLE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductTypeList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/type', { headers: getHeader() })
        commit('SET_PRODUCT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductCategoryList({ commit }) {
      try {
        const data = await axios.get(productUrl + '/category', { headers: getHeader() })
        commit('SET_PRODUCT_CATEGORY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductUomList({ commit }, payload) {
      try {
        const data = await axios.get(productUrl + '/uom', payload, { headers: getHeader() })
        commit('SET_PRODUCT_UOM_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    // Product Main & SKU
    async getProductSearch(state, payload) {
      const obj = {
        keyword: payload.keyword,
        org_no: payload.org_no == null ? '' : payload.org_no
      }
      try {
        console.log(obj)
        const data = await axios.post(productUrl + '/search', obj, { headers: getHeader() })
        console.log('Product Search')
        console.log(data.data.data)
        state.commit('PUSH_PRODUCT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductSearchMulti(state, payload) {
      const obj = {
        keyword: payload.keyword,
        org_no: payload.org_no == null ? '' : payload.org_no
      }
      try {
        console.log(obj)
        const data = await axios.post(productUrl + '/search-multi', obj, { headers: getHeader() })
        console.log('Product Search')
        console.log(data.data.data)
        state.commit('PUSH_PRODUCT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductList({ commit }, payload) {
      let canBeSold = true
      let canBePurchase = true
      let active = true
      let org_no = null
      let limit = null
      if (payload) {
        canBeSold = payload.canBeSold == null ? true : payload.canBeSold
        canBePurchase = payload.canBePurchase == null ? true : payload.canBePurchase
        active = payload.active == null ? true : payload.active
        org_no = payload.org_no == null ? '' : payload.org_no
        limit = payload.limit
      } else {
        canBeSold = true
        canBePurchase = true
        active = true
        org_no = ''
        limit = null
      }
      const url = productUrl + '/list?can_be_sold=' + canBeSold + '&can_be_purchase=' + canBePurchase + '&active=' + active + "&org_no=" + org_no + '&limit=' + limit
      console.log(url)
      try {
        const data = await axios.get(url, { headers: getHeader() })
        // console.log(data.data.data)
        commit('SET_PRODUCT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    // Product Main
    async getProductMainSearch(state, payload) {
      const obj = {
        keyword: payload.keyword,
        org_no: payload.org_no == null ? '' : payload.org_no
      }
      try {
        console.log(obj)
        const data = await axios.post(productUrl + '/search-main', obj, { headers: getHeader() })
        console.log('Product Search')
        console.log(data.data.data)
        state.commit('PUSH_PRODUCT_MAIN_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductMainSearchMulti(state, payload) {
      const obj = {
        keyword: payload.keyword,
        org_no: payload.org_no == null ? '' : payload.org_no
      }
      try {
        console.log(obj)
        const data = await axios.post(productUrl + '/search-main-multi', obj, { headers: getHeader() })
        console.log('Product Search')
        console.log(data.data.data)
        state.commit('PUSH_PRODUCT_MAIN_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getProductMainList({ commit }, payload) {
      let canBeSold = true
      let canBePurchase = true
      let active = true
      let limit = 0
      let orgNo = null
      if (payload) {
        canBeSold = payload.canBeSold == null ? true : payload.canBeSold
        canBePurchase = payload.canBePurchase == null ? true : payload.canBePurchase
        active = payload.active == null ? true : payload.active
        limit = payload.limit == null ? 0 : payload.limit
        orgNo = payload.org_no == null ? '' : payload.org_no
      }
      const url = productUrl + '/list-main?can_be_sold=' + canBeSold + '&can_be_purchase=' + canBePurchase + '&active=' + active + '&limit=' + limit + '&org_no=' + orgNo
      try {
        const data = await axios.get(url, { headers: getHeader() })
        commit('SET_PRODUCT_MAIN_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
