import axios from "axios";
import { getHeader, purchaseUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    purchaseList: [],
    purchaseListInventory: [],
    purchase: {
      id: null,
      purchase_no: null,
      details: [],
      payments: []
    },
    showPurchaseListModal: false,
    showPurchaseFormModal: false,
    showPurchaseShippingFormModal: false,
    showPurchaseDetailFormModal: false,
    showPurchasePaymentFormModal: false,
    showPurchaseImportModal: false,
    showPurchaseShowModal: false,
    showPurchaseCreateNextModal: false,
    showPurchaseDetailListModal: false,
    showPurchaseReturModal: false,
    showPurchaseCreateNextReceiptModal: false,
    showPurchaseCreateNextBillModal: false
  },
  mutations: {
    SET_PURCHASE_LIST (state, obj) {
      state.purchaseList = obj
    },
    SET_PURCHASE (state, obj) {
      state.purchase = obj
    },
    SET_SHOW_PURCHASE_LIST_MODAL (state, obj) {
      state.showPurchaseListModal = obj
    },
    SET_SHOW_PURCHASE_FORM_MODAL (state, obj) {
      state.showPurchaseFormModal = obj
    },
    SET_SHOW_PURCHASE_SHIPPING_FORM_MODAL (state, obj) {
      state.showPurchaseShippingFormModal = obj
    },
    SET_SHOW_PURCHASE_DETAIL_FORM_MODAL (state, obj) {
      state.showPurchaseDetailFormModal = obj
    },
    SET_PURCHASE_LIST_INVENTORY (state, obj) {
      state.purchaseListInventory = obj
    },
    SET_PURCHASE_PAYMENT_FORM_MODAL (state, obj) {
      state.showPurchasePaymentFormModal = obj
    },
    SET_SHOW_PURCHASE_IMPORT_MODAL (state, obj) {
      state.showPurchaseListModal = obj
    },
    SET_SHOW_PURCHASE_SHOW_MODAL (state, obj) {
      state.showPurchaseShowModal = obj
    },
    SET_SHOW_PURCHASE_CREATE_NEXT_MODAL (state, obj) {
      state.showPurchaseCreateNextModal = obj
    },
    SET_SHOW_PURChASE_DETAIL_LIST_MODAL (state, obj) {
      state.showPurchaseDetailListModal = obj
    },
    SET_SHOW_PURCHASE_RETUR_MODAL (state, obj) {
      state.showPurchaseReturModal = obj
    },
    SET_SHOW_PURCHASE_CREATE_NEXT_RECEIPT_MODAL (state, obj) {
      state.showPurchaseCreateNextReceiptModal = obj
    },
    SET_SHOW_PURCHASE_CREATE_BILL_RECEIPT_MODAL (state, obj) {
      state.showPurchaseCreateNextBillModal = obj
    }
  },
  actions: {
    enableShowPurchaseCreateNextBillModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_CREATE_BILL_RECEIPT_MODAL', true)
    },
    disableShowPurchaseCreateNextBillModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_CREATE_BILL_RECEIPT_MODAL', false)
    },
    enableShowPurchaseCreateNextReceiptModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_CREATE_NEXT_RECEIPT_MODAL', true)
    },
    disableShowPurchaseCreateNextReceiptModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_CREATE_NEXT_RECEIPT_MODAL', false)
    },
    enableShowPurchaseReturModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_RETUR_MODAL', true)
    },
    disableShowPurchaseReturModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_RETUR_MODAL', false)
    },
    enableShowPurchaseDetailListModal ({ commit }) {
      commit('SET_SHOW_PURChASE_DETAIL_LIST_MODAL', true)
    },
    disableShowPurchaseDetailListModal ({ commit }) {
      commit('SET_SHOW_PURChASE_DETAIL_LIST_MODAL', false)
    },
    enableShowPurchaseCreateNextModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_CREATE_NEXT_MODAL', true)
    },
    disableShowPurchaseCreateNextModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_CREATE_NEXT_MODAL', false)
    },
    enableShowPurchaseShowModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_SHOW_MODAL', true)
    },
    disableShowPurchaseShowModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_SHOW_MODAL', false)
    },
    enablePurchasePaymentFormModal ({ commit }) {
      commit('SET_PURCHASE_PAYMENT_FORM_MODAL', true)
    },
    disablePurchasePaymentFormModal ({ commit }) {
      commit('SET_PURCHASE_PAYMENT_FORM_MODAL', false)
    },
    enablePurchaseListFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_LIST_MODAL', true)
    },
    disablePurchaseListFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_LIST_MODAL', false)
    },
    enablePurchaseDetailFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_DETAIL_FORM_MODAL', true)
    },
    disablePurchaseDetailFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_DETAIL_FORM_MODAL', false)
    },
    enablePurchaseShippingFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_SHIPPING_FORM_MODAL', true)
    },
    disablePurchaseShippingFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_SHIPPING_FORM_MODAL', false)
    },
    enableShowPurchaseFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_FORM_MODAL', true)
    },
    disableShowPurchaseFormModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_FORM_MODAL', false)
    },
    enableShowPurchaseListModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_IMPORT_MODAL', true)
    },
    disableShowPurchaseListModal ({ commit }) {
      commit('SET_SHOW_PURCHASE_IMPORT_MODAL', false)
    },
    async getPurchaseByNo ({ commit }, payload) {
      try {
        const data = await axios.post(purchaseUrl + '/get-by-no', payload, { headers: getHeader() })
        commit('SET_PURCHASE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getPurchase({ commit }, payload) {
      try {
        const data = await axios.get(purchaseUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_PURCHASE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getPurchaseList({ commit }, payload) {
      try {
        const body = {
          contact_no: payload.contact_no,
          invoice: payload.invoice
        }
        console.log(body)
        const data = await axios.post(purchaseUrl + '/list', body, { headers: getHeader() })
        commit('SET_PURCHASE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getPurchaseListInventory({ commit }, payload) {
      try {
        const data = await axios.post(purchaseUrl + '/list-for-inventory', payload, { headers: getHeader() })
        commit('SET_PURCHASE_LIST_INVENTORY', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
