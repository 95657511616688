import axios from "axios";
import { getHeader, saleUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    saleTypeList: [],
    saleList: [],
    saleListOutstanding: [],
    sale: {},
    saleDocument: {},
    saleType: {
      id: null,
      name: null,
      org_no: null
    },
    showSaleListModal: false,
    showSaleFormModal: false,
    showSaleShowModal: false,
    showSaleTypeFormModal: false,
    showSaleTypeListModal: false,
    showSaleDetailFormModal: false,
    showSalePaymentFormModal: false,
    showSaleCreateNextModal: false,
    showSaleDocumentPaymentFormModal: false,
    showSaleDocumentLeasingPaymentFormModal: false,
    showSaleDocumentReceiveModal: false,
    showSaleReturFormModal: false,
    showSaleCreateNextInvoiceModal: false,
    showSaleCreateNextDeliverModal: false
  },
  mutations: {
    SET_SALE_TYPE (state, obj) {
      state.saleType = obj
    },
    SET_SALE_TYPE_LIST (state, obj) {
      state.saleTypeList = obj
    },
    SET_SALE_LIST_OUTSTANDING (state, obj) {
      state.saleListOutstanding = obj
    },
    SET_SALE_LIST (state, obj) {
      state.saleList = obj
    },
    SET_SALE (state, obj) {
      state.sale = obj
    },
    SET_SHOW_SALE_FORM_MODAL (state, obj) {
      state.showSaleFormModal = obj
    },
    SET_SHOW_SALE_LIST_MODAL (state, obj) {
      state.showSaleListModal = obj
    },
    SET_SHOW_SALE_TYPE_FORM_MODAL (state, obj) {
      state.showSaleTypeFormModal = obj
    },
    SET_SHOW_SALE_TYPE_LIST_MODAL (state, obj) {
      state.showSaleTypeListModal = obj
    },
    SET_SHOW_SALE_SHOW_MODAL (state, obj) {
      state.showSaleShowModal = obj
    },
    SET_SHOW_SALE_DETAIL_FORM_MODAL (state, obj) {
      state.showSaleDetailFormModal = obj
    },
    SET_SHOW_SALE_PAYMENT_FORM_MODAL (state, obj) {
      state.showSalePaymentFormModal = obj
    },
    SET_SHOW_SALE_CREATE_NEXT_MODAL (state, obj) {
      state.showSaleCreateNextModal = obj
    },
    SET_SHOW_SALE_DOCUMENT_FORM_MODAL (state, obj) {
      state.showSaleDocumentPaymentFormModal = obj
    },
    SET_SHOW_SALE_DOCUMENT_LEASING_PAYMENT_FORM_MODAL (state, obj) {
      state.showSaleDocumentLeasingPaymentFormModal = obj
    },
    SET_SALE_DOCUMENT_BY_NO_LIST (state, obj) {
      state.saleDocument = obj
    },
    SET_SALE_DOCUMENT_RECEIVE_MODAL (state, obj) {
      state.showSaleDocumentReceiveModal = obj
    },
    SET_SHOW_SALE_DOCUMENT_FORM_RETUR_MODAL (state, obj) {
      state.showSaleReturFormModal = obj
    },
    SET_SHOW_SALE_CREATE_NEXT_INVOICE_MODAL (state, obj) {
      state.showSaleCreateNextInvoiceModal = obj
    },
    SET_SHOW_SALE_CREATE_NEXT_DELIVER_MODAL (state, obj) {
      state.showSaleCreateNextDeliverModal = obj
    }
  },
  actions: {
    enableShowSaleCreateNextInvoiceModal ({ commit }) {
      commit('SET_SHOW_SALE_CREATE_NEXT_INVOICE_MODAL', true)
    },
    disableShowSaleCreateNextInvoiceModal ({ commit }) {
      commit('SET_SHOW_SALE_CREATE_NEXT_INVOICE_MODAL', false)
    },
    enableShowSaleCreateNextDeliverModal ({ commit }) {
      commit('SET_SHOW_SALE_CREATE_NEXT_DELIVER_MODAL', true)
    },
    disableShowSaleCreateNextDeliverModal ({ commit }) {
      commit('SET_SHOW_SALE_CREATE_NEXT_DELIVER_MODAL', false)
    },
    enableShowSaleReturFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DOCUMENT_FORM_RETUR_MODAL', true)
    },
    disableShowSaleReturFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DOCUMENT_FORM_RETUR_MODAL', false)
    },
    enableShowSaleDocumentReceiveModal ({ commit }) {
      commit('SET_SALE_DOCUMENT_RECEIVE_MODAL', true)
    },
    disableShowSaleDocumentReceiveModal ({ commit }) {
      commit('SET_SALE_DOCUMENT_RECEIVE_MODAL', false)
    },
    enableShowSaleDocumentLeasingPaymentFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DOCUMENT_LEASING_PAYMENT_FORM_MODAL', true)
    },
    disableShowSaleDocumentLeasingPaymentFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DOCUMENT_LEASING_PAYMENT_FORM_MODAL', false)
    },
    enableShowSaleDocumentPaymentFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DOCUMENT_FORM_MODAL', true)
    },
    disableShowSaleDocumentPaymentFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DOCUMENT_FORM_MODAL', false)
    },
    enableShowSaleCreateNextModal ({ commit }) {
      commit('SET_SHOW_SALE_CREATE_NEXT_MODAL', true)
    },
    disableShowSaleCreateNextModal ({ commit }) {
      commit('SET_SHOW_SALE_CREATE_NEXT_MODAL', false)
    },
    enableShowSalePaymentFormModal ({ commit }) {
      commit('SET_SHOW_SALE_PAYMENT_FORM_MODAL', true)
    },
    disableShowSalePaymentFormModal ({ commit }) {
      commit('SET_SHOW_SALE_PAYMENT_FORM_MODAL', false)
    },
    enableShowSaleDetailFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DETAIL_FORM_MODAL', true)
    },
    disableShowSaleDetailFormModal ({ commit }) {
      commit('SET_SHOW_SALE_DETAIL_FORM_MODAL', false)
    },
    enableShowSaleTypeFormModal ({ commit }) {
      commit('SET_SHOW_SALE_TYPE_FORM_MODAL', true)
    },
    disableShowSaleTypeFormModal ({ commit }) {
      commit('SET_SHOW_SALE_TYPE_FORM_MODAL', false)
    },
    enableShowSaleTypeListModal ({ commit }) {
      commit('SET_SHOW_SALE_TYPE_LIST_MODAL', true)
    },
    disableShowSaleTypeListModal ({ commit }) {
      commit('SET_SHOW_SALE_TYPE_LIST_MODAL', false)
    },
    enableShowSaleFormModal ({ commit }) {
      commit('SET_SHOW_SALE_FORM_MODAL', true)
    },
    disableShowSaleFormModal ({ commit }) {
      commit('SET_SHOW_SALE_FORM_MODAL', false)
    },
    enableShowSaleShowModal ({ commit }) {
      commit('SET_SHOW_SALE_SHOW_MODAL', true)
    },
    disableShowSaleShowModal ({ commit }) {
      commit('SET_SHOW_SALE_SHOW_MODAL', false)
    },
    enableShowSaleListModal ({ commit }) {
      commit('SET_SHOW_SALE_LIST_MODAL', true)
    },
    disableShowSaleListModal ({ commit }) {
      commit('SET_SHOW_SALE_LIST_MODAL', false)
    },
    async setSaleType ({ commit }, payload) {
      commit("SET_SALE_TYPE", payload)
    },
    async getSale({ commit }, payload) {
      try {
        const data = await axios.get(saleUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_SALE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getSaleTypeList({ commit }) {
      try {
        const data = await axios.get(saleUrl + '/type', { headers: getHeader() })
        commit('SET_SALE_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getSaleList({ commit }, payload) {
      // payload: contact_no, begin, end
      try {
        const data = await axios.post(saleUrl + '/list', payload, { headers: getHeader() })
        commit('SET_SALE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getSaleDocumentByNo({ commit }, payload) {
      try {
        const data = await axios.post(saleUrl + '/document-leasing/byNo', payload, { headers: getHeader() })
        commit('SET_SALE_DOCUMENT_BY_NO_LIST', data.data.data)
      } catch (error) {
        console.log(error.message)
      }
    },
    async getSaleListOutstanding({ commit }, payload) {
      try {
        const data = await axios.post(saleUrl + '/list/outstanding', payload, { headers: getHeader() })
        commit('SET_SALE_LIST_OUTSTANDING', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
